import { Grid2, Link, Typography } from "@mui/material";
import Iconify from "../Iconify";

export function AccommodationsPage() {
  return (
    <Grid2
      container
      alignItems="center"
      justifyContent="center"
      margin="16px 12px"
      padding={2}
    >
      <Grid2 container size={{ xs: 12, sm: 8 }} spacing={2} marginTop={3}>
        <Typography variant="body1">
          We invite you all to stay with us for the entire long weekend at{" "}
          <Link href="https://maps.app.goo.gl/owbJAkVFsgiTvoCG8">
            Wilderness Tours White Water Resort
            <Iconify icon="mdi:external-link" />
          </Link>
          . Calvin and Becky will be frolicking throughout the grounds from Fri
          Aug 29th to Mon Sept 1st. We will send out a booking link and
          information about how to reserve at a later date, alongside the
          official invitation.
        </Typography>
        <Typography variant="body1">
          To stay at the resort, there is a 2 night minimum or a +50% surcharge
          for a 1 night stay. The options to stay on-site are:
          <ul>
            <li>
              <Link href="https://wildernesstours.com/accommodation/onsite/bayside-suites/">
                Bayside suites
                <Iconify icon="mdi:external-link" />
              </Link>
            </li>
            <li>
              <Link href="https://wildernesstours.com/accommodation/onsite/cedar-cabins/">
                {" "}
                Cedar Cabins
                <Iconify icon="mdi:external-link" />
              </Link>
            </li>
            <li>
              <Link href="https://wildernesstours.com/accommodation/onsite/glamping-tent/">
                Glamping tents
                <Iconify icon="mdi:external-link" />
              </Link>
            </li>
            <li>
              Bring your own place to sleep by renting an{" "}
              <Link href="https://wildernesstours.com/accommodation/rvs/pavillion-rv-w-hook-ups/">
                RV
                <Iconify icon="mdi:external-link" />
              </Link>{" "}
              or{" "}
              <Link href="https://wildernesstours.com/accommodation/camping/paddlers-meadow/">
                tent
                <Iconify icon="mdi:external-link" />
              </Link>{" "}
              camping site.
            </li>
          </ul>
          Please note the rustic nature and shared facilities of the resort, so
          you can plan your comforts accordingly.
        </Typography>
        <Typography variant="body1">
          If you are looking for a more traditional hotel experience, we can
          provide a shuttle on the wedding day to a hotel option in Renfrew,
          which is about a 30 minute drive from the venue.
        </Typography>
      </Grid2>
    </Grid2>
  );
}
